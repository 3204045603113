import React from 'react'
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import { deepOrange } from '@material-ui/core/colors';


export type MessageType = {
    myTurn: boolean;
    message: string;
    timestamp: number;
    photoURL: string | undefined;
    displayName: string;
    onDeletionClick: () => void;
};

export type MessagesType = {
    id: string;
    messages: MessageType[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        messageRow: {
            display: "flex",
            width: "calc(100% - 70px)",
            marginLeft: "10px"
        },
        messageRowRight: {
            display: "flex",
            float: 'right',
            marginRight: '10px',
            justifyContent: "flex-end",
            width: "calc(100% - 70px)",
        },
        messageBlue: {
            position: "relative",
            marginTop: "15px",
            marginLeft: "20px",
            marginBottom: "10px",
            padding: "10px",
            backgroundColor: "#A8DDFD",
            maxWidth: "90%",
            //height: "50px",
            textAlign: "left",
            font: "400 .9em 'Open Sans', sans-serif",
            border: "1px solid #97C6E3",
            borderRadius: "10px",
            whiteSpace: "pre-wrap",
            '&:after': {
                content: "''",
                position: "absolute",
                width: "0",
                height: "0",
                borderTop: "15px solid #A8DDFD",
                borderLeft: "15px solid transparent",
                borderRight: "15px solid transparent",
                top: "0",
                left: "-15px",
            },
            '&:before': {
                content: "''",
                position: "absolute",
                width: "0",
                height: "0",
                borderTop: "17px solid #97C6E3",
                borderLeft: "16px solid transparent",
                borderRight: "16px solid transparent",
                top: "-1px",
                left: "-17px",
            },
        },
        messageOrange: {
            position: "relative",
            marginTop: "15px",
            marginRight: "20px",
            marginBottom: "10px",
            padding: "10px",
            backgroundColor: "#f8e896",
            maxWidth: "90%",
            //height: "50px",
            textAlign: "left",
            font: "400 .9em 'Open Sans', sans-serif",
            border: "1px solid #dfd087",
            borderRadius: "10px",
            whiteSpace: "pre-wrap",
            '&:after': {
                content: "''",
                position: "absolute",
                width: "0",
                height: "0",
                borderTop: "15px solid #f8e896",
                borderLeft: "15px solid transparent",
                borderRight: "15px solid transparent",
                top: "0",
                right: "-15px",
            },
            '&:before': {
                content: "''",
                position: "absolute",
                width: "0",
                height: "0",
                borderTop: "17px solid #dfd087",
                borderLeft: "16px solid transparent",
                borderRight: "16px solid transparent",
                top: "-1px",
                right: "-17px",
            },
        },

        messageContent: {
            padding: 0,
            margin: 0,
        },  
        messageTimeStampRight: {
            position: "absolute",
            fontSize: ".85em",
            fontWeight: 300,
            marginTop: "10px",
            bottom: "-20px",
            right: "0px",
        },
        messageDeletion: {
            position: "absolute",
            fontSize: ".70em",
            fontWeight: 300,
            marginTop: "10px",
            // marginLeft: "50px",
            textAlign: "right",
            width: "50px",
            bottom: "-20px",
            right: "40px",
            display: "inline-block",
            cursor: "pointer"
        },

        orange: {
            color: theme.palette.getContrastText(deepOrange[500]),
            backgroundColor: deepOrange[500],
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        avatarNothing: {
            color: "transparent",
            backgroundColor: "transparent",
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        displayName: {
            marginLeft: "20px",
        },
    })
);

//avatarが左にあるメッセージ（他人）
export const MessageLeft: React.FC<MessageType> = (props) => {
    const classes = useStyles();
    const date = new Date(props.timestamp);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const timestamp = hours + ":" + minutes
    return (
        <div className={classes.messageRow}>

            <Avatar alt={"自分"} className={classes.orange}  src={props.photoURL} ></Avatar> 
            <div>
            {/* <div className={classes.displayName}>{props.displayName}</div> */}
            <div className={classes.messageBlue}>
                <div><p className={classes.messageContent}>{ props.message }</p></div>
                <div className={classes.messageTimeStampRight}>{timestamp}</div>
                <span className={classes.messageDeletion} onClick={props.onDeletionClick}>削除</span>
            </div>
            </div>
        </div>
    )
}
//自分のメッセージ
export const MessageRight: React.FC<MessageType> = (props) => {
    const classes = useStyles();
    const date = new Date(props.timestamp);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const timestamp = hours + ":" + minutes
    return (
        <div className={classes.messageRowRight}>
            <div className={classes.messageOrange}>
                <p className={classes.messageContent}>{ props.message }</p>
                <div className={classes.messageTimeStampRight}>{ timestamp }</div>
                <span className={classes.messageDeletion} onClick={props.onDeletionClick}>削除</span>
            </div>
            <Avatar alt={"相談役"} className={classes.orange}  src={props.photoURL} ></Avatar> 
        </div>
    )
}

export default MessageRight;