import React, {useRef, useState} from 'react'
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import { styled, createTheme, ThemeProvider } from "@material-ui/core/styles";
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import { MessagesType } from './Message';

const customTheme = createTheme();

type TextInputType = {
    msgs: MessagesType;
    setMsgs: React.Dispatch<React.SetStateAction<MessagesType>>;
    myTurn: boolean;
    setMyTurn: React.Dispatch<React.SetStateAction<boolean>>;
};

const TextInput: React.FC<TextInputType> = (props) => {
    const [text, setText] = useState<string>("");
    const inputRef = useRef<HTMLElement>(null);
    const submit = () => {
        var msgs = Object.assign({}, props.msgs);
        
        const deleteMsg = (index: number) => {
            var messagesCopy = msgs.messages.slice();
            messagesCopy.splice(index, 1);
            localStorage.setItem(msgs.id, JSON.stringify({
            ...msgs,
            messages: messagesCopy
            }));
            props.setMsgs({
            ...msgs,
            messages: messagesCopy
            })
        };

        msgs.messages.push({
            myTurn: props.myTurn,
            message: text,
            displayName: "hoge",
            photoURL: "dummy",
            timestamp: Date.now(),
            onDeletionClick: () => deleteMsg(msgs.messages.length)
        });
        localStorage.setItem(msgs.id, JSON.stringify(msgs));
        props.setMsgs(msgs);

        setText("");
        props.setMyTurn(!props.myTurn);
        (inputRef?.current?.lastElementChild?.firstChild as HTMLTextAreaElement).focus();
    };
    let displayName = "自分";
    if (!props.myTurn) {
        displayName = "相談役"
    }
    return (
        <ThemeProvider theme={customTheme}>
            <TextForm  noValidate autoComplete="off">
                <Button onClick={() => {
                    props.setMyTurn(!props.myTurn);
                }}>
                    <Avatar alt={displayName} src={"dummy.jpg"} />
                </Button>
                <TextInputTF
                    id="standard-text"
                    label="メッセージを入力"
                    value={text}
                    multiline
                    autoFocus
                    onChange={e => {
                        setText(e.target.value);
                    }}
                    innerRef={inputRef}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && ((e.ctrlKey && !e.metaKey) || (!e.ctrlKey && e.metaKey))) {
                            e.preventDefault();
                            if (text.trim().length !== 0) {
                                submit();
                            }
                        }
                    }}
                />
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={submit} 
                    disabled={text.trim().length === 0}
                >
                    <SendIcon />
                </Button>
            </TextForm>
        </ThemeProvider>
    )
}

export default TextInput;

const TextForm = styled(`form`)(({theme}) => ({
    display: "flex",
    justifyContent: "space-around",
    // width: "95vw",
    width: "95%",
    margin: `${theme.spacing(1)} auto`,
    alignItems: "center"
}));

const TextInputTF = styled(TextField)({
    width: '100%',
    margin: "0 10px",
});