// 関連するコンポーネントのみimportのコードをデモコードからコピーしてくる
import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer'
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { ListItemButton } from '@mui/material';
import { ListItemType } from './Layout';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, ListItem } from '@material-ui/core';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

const drawerWidth = 240;

type SideMenuType = {
  selectedIdx: number;
  setSelectedIdx: React.Dispatch<React.SetStateAction<number>>;
  listItems: ListItemType[];
  setListItems: React.Dispatch<React.SetStateAction<ListItemType[]>>;
  onAddButtonClick: React.MouseEventHandler<HTMLButtonElement>;
  onSelect: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => void;
  onDelete: (index: number) => void;
};

type DialogType = {
  open: boolean;
  handleClose: () => void;
  onDelete: () => void;
};

const SideMenu: React.FC<SideMenuType> = (props) => {
  const[ dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const onDelete = (index: number) => {
    props.onDelete(index);
  };

  const onDeleteClick = () => {
    setDialogOpen(true);
  };

  const reorder = (startIndex: number, endIndex: number) => {
    console.log(startIndex);
    console.log(endIndex);
    const listItemsCopy = props.listItems.slice();
    const [removed] = listItemsCopy.splice(startIndex, 1);
    listItemsCopy.splice(endIndex, 0, removed);
    // listItemsCopy.map((row, index) => (row.order = index));
    return listItemsCopy;
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const update = reorder(source.index, destination.index);
    props.setListItems(update);
    if (props.selectedIdx === source.index) {
      props.setSelectedIdx(destination.index);
    } else if (props.selectedIdx < source.index && props.selectedIdx >= destination.index) {
      props.setSelectedIdx(props.selectedIdx + 1);
    } else if (props.selectedIdx > source.index && props.selectedIdx <= destination.index) {
      props.setSelectedIdx(props.selectedIdx - 1);
    }
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <IconButton onClick={props.onAddButtonClick}>
        <AddBoxOutlinedIcon/>
      </IconButton>
      <Divider />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='dndTableBody'>
          {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
              {props.listItems.map((msgs, index) => {
                return (
                  <Draggable
                    draggableId={msgs.id}
                    index={index}
                    key={msgs.id}
                  >
                    {(provided) => (
                      <ListItem 
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ListItemButton 
                          key={msgs.id} 
                          selected={props.selectedIdx === index} 
                          sx={{width: "100%"}} 
                          onClick={e => props.onSelect(e, index)}
                        >
                          {msgs.title}
                        </ListItemButton>
                        <IconButton size="small" onClick={onDeleteClick}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItem>
                    )}
                  </Draggable>
              )})}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      <AlertDialog open={dialogOpen} handleClose={handleClose} onDelete={() => {
        onDelete(props.selectedIdx);
        setDialogOpen(false);
      }} />
    </Drawer>
  )
};

const AlertDialog: React.FC<DialogType> = (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            削除してもよろしいでしょうか。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} autoFocus>いいえ</Button>
          <Button onClick={props.onDelete}>
            はい
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SideMenu;