import React, { useState, useRef, useLayoutEffect } from 'react'
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Paper, } from '@material-ui/core';
import TextInput from './TextInput';
import TextField from '@mui/material/TextField';
import {MessageLeft, MessageRight, MessagesType} from './Message';

type MsgsProps = {
    id: string;
    title: string;
    changeTitle: (title: string) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
        paper: {  
            // width: '100vw',
            // height: '100vh',
            width: '100%',
            // height: '100vh',
            height: 'calc(100vh - 80px)',
            // maxWidth: "500px",
            // maxHeight: "700px",
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative'
        },
        container: {
            // width: '100vw',
            // height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        messagesBody: {
            width: 'calc( 100% - 20px )', 
            margin: 10,
            overflowY: 'scroll', 
            // height: 'calc( 100% - 80px )'
            height: '100%'
        },
        textBody: {
          height: '30px',
          paddingLeft: '10px',
          fontSize: '20px'
        }
    })
);

const MessageContainer = (props: MsgsProps) => {
  const [msgs, setMsgs] = useState<MessagesType>(
    JSON.parse(localStorage.getItem(props.id)!) as MessagesType
  );
  const [myTurn, setMyTurn] = useState<boolean>(true);
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  useLayoutEffect(() => {
    if(scrollBottomRef && scrollBottomRef.current) {
      scrollBottomRef.current.scrollIntoView();
    }
  })
  if (props.id !== msgs.id) {
    const msgTmp = JSON.parse(localStorage.getItem(props.id)!) as MessagesType;
    // if (msgTmp.messages.length > 0) {
    //   let lastMyTrun = msgTmp.messages[msgTmp.messages.length-1]["myTurn"];
    //   setMyTurn(!lastMyTrun);
    // }
    setMsgs(msgTmp);
  }

  // useEffect(() => {
  //   if (msgs.messages.length > 0) {
  //     let lastMyTrun = msgs.messages[msgs.messages.length-1]["myTurn"];
  //     setMyTurn(!lastMyTrun);
  //   }
  // }, []);
  
  const deleteMsg = (index: number) => {
    var messagesCopy = msgs.messages.slice();
    messagesCopy.splice(index, 1);
    localStorage.setItem(msgs.id, JSON.stringify({
      ...msgs,
      messages: messagesCopy
    }));
    setMsgs({
      ...msgs,
      messages: messagesCopy
    })
  };

  const msgComponent = msgs.messages.map((value, index) => {
    if (value.myTurn) {
      return <MessageLeft 
        message={value.message} 
        timestamp={value.timestamp} 
        photoURL={value.photoURL} 
        displayName={value.displayName} 
        myTurn={value.myTurn}
        onDeletionClick={() => deleteMsg(index)}
      />;
    } else {
      return <MessageRight 
        message={value.message} 
        timestamp={value.timestamp} 
        photoURL={value.photoURL} 
        displayName={value.displayName} 
        myTurn={value.myTurn}
        onDeletionClick={() => deleteMsg(index)}
      />;
    }
  });
  let textInputComponent = <TextInput msgs={msgs} setMsgs={setMsgs} myTurn={myTurn} setMyTurn={setMyTurn} />;

  return (
    <div className={classes.container}>
        <Paper className={classes.paper}>
            <TextField 
              InputProps={{classes: { input: classes.textBody}}} 
              id="title-text" 
              variant="outlined" 
              label="title" 
              fullWidth={true} 
              onChange={e => props.changeTitle(e.target.value)} 
              onBlur={(e) => {
                if (e.target.value.length === 0) {
                  props.changeTitle("タイトル");
                }
              }}
              value={props.title}
              autoComplete='off'
            />
            <Paper id="style-1" className={classes.messagesBody}>
            {msgComponent}
            <div ref={scrollBottomRef}/>
                {/* <MessageLeft message="あめんぼあかいなあいうえお" timestamp="MM/DD 00:00" photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c" displayName=""/>
                <MessageLeft message="xxxxxhttps://yahoo.co.jp xxxxxxxxxあめんぼあかいなあいうえおあいうえおかきくけこさぼあかいなあいうえおあいうえおかきくけこさぼあかいなあいうえおあいうえおかきくけこさいすせそ" timestamp="MM/DD 00:00" photoURL="" displayName="テスト" />
                <MessageRight message="messageRあめんぼあかいなあいうえおあめんぼあかいなあいうえおあめんぼあかいなあいうえお" timestamp="MM/DD 00:00" photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c" displayName="まさりぶ" />
                <MessageRight message="messageRあめんぼあかいなあいうえおあめんぼあかいなあいうえお" timestamp="MM/DD 00:00" photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c" displayName="まさりぶ" />
                <MessageRight message="messageRあめんぼあかいなあいうえおあめんぼあかいなあいうえお" timestamp="MM/DD 00:00" photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c" displayName="まさりぶ" />
                <MessageRight message="messageRあめんぼあかいなあいうえおあめんぼあかいなあいうえお" timestamp="MM/DD 00:00" photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c" displayName="まさりぶ" />
                <MessageRight message="messageRあめんぼあいなあいうえおあめんぼあかいなあいうえお" timestamp="MM/DD 00:00" photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c" displayName="まさりぶ" />
                <MessageRight message="messageRあめんぼあかいなあいうえおあめんぼあかいなあいうえお" timestamp="MM/DD 00:00" photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c" displayName="まさりぶ" /> */}
            </Paper>
            {textInputComponent}
        </Paper>
    </div>
  );
}

export default MessageContainer;