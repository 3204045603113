import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MessageContainer from './MessageContainer';
import { v4 as uuidv4 } from 'uuid';
import { MessagesType } from './Message';
import React, { useState } from 'react';
import SideMenu from './SideMenu';

export type ListItemType = {
  id: string;
  title: string;
  // order: number;
};

export default function Layout() {
  const [selectedIdx, setSelectedIdx] = useState<number>(0);
  const [listItems, setListItems] = useState<ListItemType[]>(() => {
    let listItemsStr = localStorage.getItem("listItems");
    if (typeof listItemsStr === "string") {
      return JSON.parse(listItemsStr)
    } else {
      let listItemsTmp: ListItemType[] = [];
      localStorage.setItem("listItems", JSON.stringify(listItemsTmp));
      return listItemsTmp
    }

  });

  const changeTitle = (title: string) => {
    const newListItems = listItems.slice();
    newListItems[selectedIdx].title = title;
    setListItems(newListItems);
    localStorage.setItem("listItems", JSON.stringify(newListItems));
  };

  const onAddButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    let id = uuidv4();
    let title = "タイトル";
    let msgs: MessagesType = {
      id: id,
      messages: []
    };
    var listItemsCopy = listItems.slice();
    listItemsCopy.unshift({
      id: id,
      title: title,
      // order: 0
    });
    localStorage.setItem("listItems", JSON.stringify(listItemsCopy));
    localStorage.setItem(id, JSON.stringify(msgs));
    setListItems(listItemsCopy);
  };

  const onSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedIdx(index);
  };

  const onDelete = (index: number) => {
    localStorage.removeItem(listItems[index].id);
    var listItemsCopy = listItems.slice();
    listItemsCopy.splice(index, 1);
    localStorage.setItem("listItems", JSON.stringify(listItemsCopy));
    setListItems(listItemsCopy);
    if (selectedIdx >= index && index > 0) {
      setSelectedIdx(selectedIdx - 1);
    } else {
      setSelectedIdx(selectedIdx);
    }
  };

  var msgContainer: JSX.Element | null = null;
  if (listItems.length > 0) {
    msgContainer = <MessageContainer id={listItems[selectedIdx].id} title={listItems[selectedIdx].title} changeTitle={changeTitle} />
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <SideMenu 
        listItems={listItems} 
        setListItems={setListItems}
        onAddButtonClick={onAddButtonClick} 
        selectedIdx={selectedIdx} 
        setSelectedIdx={setSelectedIdx}
        onSelect={onSelect} 
        onDelete={onDelete} 
      />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
      {msgContainer}
    </Box>
    </Box>
  );
}